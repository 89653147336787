import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Hero from "../components/Blocks/Hero"
import styled from "styled-components"
import CookingInstructionsBlock from "../components/Product/CookingInstructionsBlock"
import colors from "../styles/colors"
import RestaurantBlock from "../components/Product/DynamicBlocks/RestaurantBlock"
import { Col, Row } from "styled-bootstrap-grid"
import breakpoints from "../styles/breakpoints"
import {
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "src/components/Layout/Layout"
import Gallery from "../components/Product/Gallery"
import TextInformation from "../components/CookingInstructions/TextInformation"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Text from "../components/Blocks/SmallText"
import Dropdown from "../components/CookingInstructions/DropDown"
import ELink from "../components/Core/ExternalLink"
import fonts from "../styles/fonts"

const StyledLink = styled(ELink)`
  :hover {
    background: ${colors.lightBlue};
    color: white;
  }
  filter: drop-shadow(3.16071px 3.16071px 0px rgba(0, 0, 0, 0.25));
  border-radius: 300px;
  border-width: 2px;
  border-style: solid;
  transition: 0.1s background-color linear, 0.1s color linear;

  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  padding: 0.4em 1.8em;
  ${"" /* font-size: calc((1 - 1) * 1.2vw + 1rem); */}
  cursor: pointer;
  display: inline-block;
  color: grey;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  font-size: calc(1vw + 0.5em);
  line-height: 3vw;
  background-color: white;
  @media (min-width: 1600px) {
    font-size: calc(16px + 0.5em);
    line-height: 48px;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 18px;
    bottom: 14px;
    position: relative;
  }
`

const Heading = styled.h2`
  color: ${(props) => (props.bg === "White" ? colors.royalBlue : colors.white)};
  line-height: 1.104;
  margin-top: calc(-1em - 1.5%);
  position: relative;
  z-index: 1;
  display: block;
  span {
    background: ${(props) =>
      props.highlight === "Blue"
        ? colors.royalBlue
        : props.highlight === "Yellow"
        ? colors.yellow
        : "transparent"};
    webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    padding: 0 3%;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0;
    width: 130%;
    font-size: 3rem;
  }

  @media (min-width: ${breakpoints.xxl}) {
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 75%;
  padding-bottom: 75%;
`

const Image = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  display: block;
`
const StyledCol = styled(Col)`
  padding-top: 25px;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 0;
  }
`

const PageContainer = styled.div`
  max-width: ${breakpoints.max};
  margin: auto;
  padding: ${`calc(${MOBILE_HEADER_HEIGHT}px + 5vw)`} 6vw 5vw;

  @media (min-width: ${breakpoints.md}) {
    padding: ${`calc(${DESKTOP_HEADER_HEIGHT}px + 5vw)`} 5vw 5vw;
  }
`

const StyledHero = styled(Hero)``

const TextContainer = styled.div`
  padding: 0;
`

const Title4 = styled.h4`
  color: ${colors.royalBlue};
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-size: calc(1.5vw + 1rem);
  line-height: 1.176;

  // padding-left: 1vw;
  // padding-right: 1vw;
  display: flex;
  justify-content: left;
`

const RestaurantName = styled.h2`
  color: rgb(235, 156, 195);
`

const ProductTitle = styled.h2`
  color: ${colors.royalBlue};
`

const Title6 = styled.h4`
  color: black;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: calc(0.5vw + 1rem);
  line-height: 1.176;
  padding-top: 1vmax;

  // padding-right: 1vw;
  display: flex;
  justify-content: left;
`

const Title2 = styled.h4`
  color: ${colors.royalBlue};
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-size: calc(2vw + 1rem);
  line-height: 1.176;
  padding-top: 1vmax;
  // padding-left: 1vw;
  // padding-right: 1vw;
  display: flex;
  justify-content: left;
`

const CookingInstructionsTemplate = (props) => {
  //console.log('swell product: ' + JSON.stringify(props.data.swellProduct));
  const cookingInstructions = props.data.contentfulCookingInstructions
  //console.log("lets see: " + props.data)
  const {
    hero,
    content,
    metaTitle,
    metaDescription,
    socialMediaImage,
    product,
  } = cookingInstructions
  let dishRatingLink
  if (product?.dishRatingLink) {
    dishRatingLink = product.dishRatingLink
  }
  const swellProduct = props.data.swellProduct
  const images = swellProduct ? swellProduct.images : undefined
  let mainProductImage
  if (images && images.length > 0) {
    mainProductImage = images[0].file.url
  }
  const ingredients = cookingInstructions.product
    ? cookingInstructions.product.ingredients ?? undefined
    : undefined
  // console.log('ingredients: '+ingredients)
  // console.log('ingredients2' + cookingInstructions.product.ingredients)
  //console.log('restaurant: ' + JSON.stringify(cookingInstructions.restaurant.description.raw));
  const path = props.location.pathname
  let sharingImage = false
  if (socialMediaImage) {
    sharingImage = socialMediaImage.file.url
  }



  const chefsRecommendations = cookingInstructions.product
    ? cookingInstructions.product.chefsRecommendations
    : undefined
  if (swellProduct) {
    return (
      <Layout dark bg={colors.lightGrey}>
        <Seo
          title={metaTitle}
          description={metaDescription}
          path={path}
          image={sharingImage}
        />
        <PageContainer>
          <Row>
            <Col md={6} mdOrder="first" mdAlignSelf="center">
              <ImageWrapper key="image">
                {images && images.length > 2 && (
                  <Image src={images[1].file.url} alt="alt" />
                )}
              </ImageWrapper>
            </Col>
            <StyledCol md={4} mdOffset={0.5}>
              <TextContainer>
                { cookingInstructions.restaurant && cookingInstructions.product ? 
                <>
                <RestaurantName>{cookingInstructions.restaurant.name}</RestaurantName>
                <ProductTitle>{cookingInstructions.slug === "pine-crane-buns" ? "Steamed Buns" : cookingInstructions.product.title }</ProductTitle>
                </> :
                swellProduct && <Title4>{swellProduct.name}</Title4>}

                {/* {dishRatingLink ? (
                  <StyledLink
                    href={dishRatingLink}
                    className={"external link"}
                    blue={"blue"}
                  >
                    Rate This Dish
                  </StyledLink>
                ) : null} */}
              </TextContainer>

              {chefsRecommendations && (
                <TextInformation
                  rating={
                    dishRatingLink ? (
                      <StyledLink
                        href={dishRatingLink}
                        className={"external link"}
                        blue={"blue"}
                      >
                        Rate This Dish
                      </StyledLink>
                    ) : null
                  }
                  content={chefsRecommendations}
                  title={"Chef's recommendations"}
                />
              )}
            </StyledCol>
          </Row>
          <Row style={{ marginTop: 50 }}>
            <Col style={{ marginLeft: 0 }} md={6} mdOffset={0.5}>
              <div>
                <ProductTitle>Cooking Instructions</ProductTitle>

                <CookingInstructionsBlock content={content} />
                {ingredients && (
                  <>
                  <Dropdown content={ingredients} pineCrane={cookingInstructions.slug === "pine-crane-buns"} title={"Ingredients"} />

                  </>
                )}
              </div>
              <Title6 style={{ marginTop: 30, marginBottom: 0 }}>
                Meet The Maker:
              </Title6>
              <Col md={3} mdOffset={0.5}></Col>
              {cookingInstructions.restaurant && (
                <Text content={cookingInstructions.restaurant.description} />
              )}
            </Col>
            <Col order-s-last md={4} mdOffset={0.5}>
              <RestaurantName>Paired Recipe</RestaurantName>
              <a
                style={{ color: colors.royalBlue }}
                target="_blank"
                href={
                  "/content-recipes/" +
                  (cookingInstructions.recipe
                    ? cookingInstructions.recipe.slug
                      ? cookingInstructions.recipe.slug
                      : ""
                    : "")
                }
              >
                <span>
                  {cookingInstructions.recipe
                    ? cookingInstructions.recipe.title
                    : ""}
                </span>
                <GatsbyImage
                  image={
                    cookingInstructions.recipe &&
                    cookingInstructions.recipe.image &&
                    cookingInstructions.recipe.image.gatsbyImageData
                  }
                  alt={
                    cookingInstructions.recipe &&
                    cookingInstructions.recipe.image &&
                    cookingInstructions.recipe.image
                      ? cookingInstructions.recipe.image.description
                      : ""
                  }
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col md={4}></Col>
            <StyledCol md={4}></StyledCol>
          </Row>
        </PageContainer>
      </Layout>
    )
  } else {
    return (
      <Layout dark bg={colors.lightGrey}>
        <Seo
          title={metaTitle}
          description={metaDescription}
          path={path}
          image={sharingImage}
        />

        <StyledHero heroData={hero} />
        <CookingInstructionsBlock content={content} />
      </Layout>
    )
  }
}
export default CookingInstructionsTemplate
export const pageQuery = graphql`
  query CookingInstructionsQuery($slug: String!, $backupslug: String!) {
    swellProduct(slug: { eq: $backupslug }) {
      id
      active
      name
      images {
        file {
          url
        }
      }
    }
    contentfulCookingInstructions(slug: { eq: $slug }) {
      slug
      recipe {
        slug
        image {
          gatsbyImageData
          description
        }
        title
      }
      product {
        id
        title
        dishRatingLink
        ingredients {
          raw
        }
        restaurant {
          address
          description {
            raw
          }
          headChef
          links {
            label
            url
          }
          name
          photo {
            gatsbyImageData(width: 1200)
            description
          }
        }
        blocks {
          ... on ContentfulRestaurant {
            id
            name
            description {
              raw
            }
          }
        }
        ingredients {
          raw
        }
        chefsRecommendations {
          raw
        }
      }

      restaurant {
        address
        description {
          raw
        }
        headChef
        links {
          label
          url
        }
        name
        photo {
          gatsbyImageData(width: 1200)
          description
        }
      }
      hero {
        content {
          raw
        }
        ctaLabel
        ctaUrl
        image {
          gatsbyImageData
          description
        }
        title
      }
      content {
        raw
      }
      metaTitle
      metaDescription
      socialMediaImage {
        file {
          url
        }
      }
    }
  }
`
