import React from "react"
import styled from "styled-components"
import fonts from "src/styles/fonts"
import colors from "src/styles/colors"
// import Text from "../../Blocks/Text"
import Text from "src/components/Product/Text"

const TextContainer = styled.div`
  padding-right:  17px; 
  padding-top:  17px; 
  padding-bottom: 17px;
  padding-left: {$props => props.noPadding ? "0px" : "17px"};
`

const Title = styled.h4`
  color: ${colors.royalBlue};
  font-size: calc(0.12vh + 1rem);
`

const Content = styled.div`
  margin-top: 2rem;
`

const TextInformation = (props) => {
  const { title, content } = props
  return (
    <TextContainer noPadding={props.noPadding}>

 
      {content && (
      <>
        <Title>{title}</Title>
          <Text content={content} />
    </>
      )}
      {props.rating ? props.rating :""}
    </TextContainer>
  )
}

export default TextInformation
