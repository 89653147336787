import React, { useState } from "react"
import styled from "styled-components"
import fonts from "src/styles/fonts"
import colors from "src/styles/colors"
import Text from "../Blocks/SmallText"

const DropdownContainer = styled.div`
  padding: 17px;
`

const Title = styled.h4`
  color: ${colors.royalBlue};
  font-size: calc((1.1 - 1) * 1.2vh + 1rem);
  cursor: pointer;
`

const Drawer = styled.div`
  margin-top: 2rem;
  display: ${(props) => (props.open ? "block" : "none")};
`

const Dropdown = (props) => {
  const [open, setOpen] = useState(false)
  const { title, content } = props
  return (
    <DropdownContainer>
      <Title onClick={() => setOpen(!open)}>
        {open ? "- " : "+ "}
        {title}
      </Title>
      <Drawer open={open}>
      {props.pineCrane && <h5>Beef</h5>}
        <Text content={content} />
        {props.pineCrane && <><h5>Bean Curd</h5><div style={{marginTop: "16px"}}><p style={{fontSize: "1rem"}}>Soy beancurd, star anise, garlic, canola oil, rice wine, soy sauce, soy paste, dark soy sauce, rock sugar, beech mushrooms, mustard greens, bok choy, peanuts, garlic, rice wine, canola oil, sesame oil, white pepper, mushroom powder, salt</p></div></>}
      </Drawer>
    </DropdownContainer>
  )
}

export default Dropdown
