import React from "react"
import styled from "styled-components"
import Text from "../Blocks/SmallText"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../Layout/Layout"
import fonts from "../../styles/fonts"

const InstructionsContainer = styled.div`
  padding-top: .5vmax;
  padding-left: 1vw;
  padding-right: 1vw;
  
  display: flex;
  justify-content: center;
`

const TextContainer = styled.div`
  color: ${colors.black};

  h2,
  h3,
  h4 {
    color: ${colors.royalBlue};
    text-align: center;
    margin-bottom: 2rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 100%;
  }
`

const CookingInstructionsBlock = (props) => {
  const content = props.content

  return (
    <InstructionsContainer>
      <TextContainer>
        <Text content={content} />
      </TextContainer>
    </InstructionsContainer>
  )
}

export default CookingInstructionsBlock
